import {
    ConnectWallet,
    useAddress,
    useContract,
    useTokenBalance, Web3Button
} from "@thirdweb-dev/react";
import {useEffect, useState} from "react";
import {utils} from "ethers";
import Link from "next/link";
import axios from "axios";
import JSConfetti from 'js-confetti'

export default function Home() {

    const walletAddress = useAddress();
    const {contract: tokenContract} = useContract(process.env.NEXT_PUBLIC_TOKEN_CONTRACT);
    const {data: tokenBalance} = useTokenBalance(tokenContract, walletAddress);

    const [claimData, setClaimData] = useState({
        maxClaimable: '',
        proof: []
    })

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [jsConfetti, setJsConfetti] = useState(null);

    const getData = async () => {
        return await axios.post('/api/get-claim-data', {wallet: walletAddress}, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    }

    const confirmClaim = async (claimData) => {
        await axios.post('/api/confirm-claim', {data: claimData}, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    }

    useEffect(() => {
        setJsConfetti(new JSConfetti())
    }, []);

    useEffect(() => {
        if (walletAddress) {
            setClaimData({
                maxClaimable: '',
                proof: []
            })
            setErrorMessage('')
            getData(walletAddress).then(data => {
                setClaimData(data.data)
            }).catch(err => {
                setErrorMessage(err.response.data)
            })
        }
    }, [walletAddress]);

    return (
        <div className='page_wrapper'>
            <header
                className={'header'}
            >
                <div className="container">
                    <Link href={'/'}>
                        <img
                            src={'/images/main_logo.svg'}
                            height='20'
                            width='145'
                            className="logo-lg"
                            alt="O2t Logo"
                        />
                    </Link>
                </div>
            </header>
            <main className={'main'}>
                <div className={'container'}>
                    <div className="claim_content">
                        <ConnectWallet/>
                        {walletAddress && (
                            <div>
                                <div className={'card'}>
                                    {errorMessage ? <small
                                        style={{
                                            width: '100%',
                                            background: '#442020',
                                            padding: '6px',
                                            borderRadius: '6px',
                                            border: '1px solid #ff3333',
                                            color: '#FFF',
                                            fontSize: '12px',
                                            textAlign: 'center',
                                            marginBottom: '10px'
                                        }}
                                    >{errorMessage}</small> : null}
                                    {successMessage ? <small
                                        style={{
                                            width: '100%',
                                            background: '#2a2f2a',
                                            padding: '6px',
                                            borderRadius: '6px',
                                            border: '1px solid #62c762',
                                            color: '#62c762',
                                            fontSize: '12px',
                                            textAlign: 'center',
                                            marginBottom: '10px'
                                        }}
                                    >{successMessage}</small> : null}
                                    <h1>Claim your <span className={'app_color'}>$O2T</span> tokens</h1>
                                    {claimData.maxClaimable && <p style={{marginBottom: '10px'}}>Tokens available to claim: <span style={{fontSize: '20px'}} className={'app_color'}>{!claimData.is_paid ? claimData.maxClaimable * 1000000000 : '0.0'}</span></p>}
                                    <p>Wallet balance: <span style={{fontSize: '20px'}} className={'app_color'}>{tokenBalance?.displayValue}</span></p>
                                    <Web3Button
                                        isDisabled={!claimData.maxClaimable || !claimData.proof.length}
                                        className={'main_button'}
                                        contractAddress={process.env.NEXT_PUBLIC_AIRDROP_CONTRACT}
                                        action={async (contract) => {
                                            await contract.call(
                                                "claim",
                                                [
                                                    walletAddress,
                                                    utils.parseEther(claimData.maxClaimable),
                                                    claimData.proof,
                                                    utils.parseEther(claimData.maxClaimable),
                                                ]
                                            )
                                        }}
                                        onError={(err) => {
                                            if (err && err.reason && !err.reason.includes('quantity')) {
                                                setErrorMessage(err.reason)
                                                setTimeout(() => {
                                                    setErrorMessage('')
                                                }, 5000)
                                            } else {
                                                setErrorMessage("Not eligible for airdrop or already claimed!")
                                                setTimeout(() => {
                                                    setErrorMessage('')
                                                }, 5000)
                                            }
                                        }}
                                        onSuccess={async (data) => {
                                            console.log('success claim', data)
                                            setSuccessMessage("Airdrop has been claimed!")
                                            setTimeout(() => {
                                                setSuccessMessage('')
                                            }, 5000)
                                            jsConfetti.addConfetti()
                                            confirmClaim({
                                                wallet: walletAddress,
                                                amount: claimData.maxClaimable
                                            }).then(d => {

                                            }).catch(err => {

                                            })
                                        }}
                                    >Claim</Web3Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
}
